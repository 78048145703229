import React from "react";
import './HomePage.scss';
import $ from 'jquery';
import { useState } from "react";
import { Helmet } from "react-helmet";

// images
import Review from '../../Images/Review.png'
import next from '../../Images/next.png'
import telephone from '../../Images/telephone.png'
import MainPic from '../../Images/MainPic.jpg'

import GarageImg1 from '../../Images/GarageImg1.jpg'
import GarageImg2 from '../../Images/GarageImg2.jpg'
import GarageImg3 from '../../Images/GarageImg3.jpg'
import GarageImg4 from '../../Images/GarageImg4.jpg'
import GarageImg6 from '../../Images/GarageImg6.jpg'
import GarageImg7 from '../../Images/GarageImg7.jpg'
import GarageImg8 from '../../Images/GarageImg8.jpg'
import GarageImg9 from '../../Images/GarageImg9.jpg'
import Checklist from '../../Images/checklist.png'
import Google from '../../Images/Google.png'
import book from '../../Images/book.png'
import LocationData from '../../Components/LocationAll/LocationData'


export default function HomePage({ navigateTo, FaqList, Blogs }) {

    function readingTime(S) {
        const text = S.Text;
        const wpm = 225;
        const words = text.trim().split(/\s+/).length;
        const time = Math.ceil(words / wpm);
        return time
    }

    const getSpecificNumberFaq = () => {
        const number = Math.random() * FaqList.length
        if (number <= (FaqList.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberFaq()
        }
    }
    const [getNumberFaq, setgetNumberFaq] = useState(getSpecificNumberFaq());
    const getSpecificNumberBlog = () => {
        const number = Math.random() * Blogs.length
        if (number <= (Blogs.length - 3) && number > 0) {
            return Math.floor(number)
        } else {
            return getSpecificNumberBlog()
        }
    }
    const [getNumberBlog, setgetNumberBlog] = useState(getSpecificNumberBlog());

    return (
        <div className="HomePage">
            <Helmet>
                {/* <!-- TITLE -->  */}
                {/* <!-- Maximum of 65 characters --> */}
                <title>Calabasas, CA Garage Door & Repair 747-338-6494 | Best Garage Door Services</title>
                {/* <!-- Maximum 35 characters --> */}
                <meta name="title" property="title" content="Calabasas, CA Garage Door & Repair 747-338-6494 | Best Garage Door" data-react-helmet="true" />
                <meta name="og:title" property="og:title" content="Calabasas, CA Garage Door & Repair 747-338-6494 | Best Garage Door" data-react-helmet="true" />
                {/* <!-- META --> */}
                <meta name="og:locale" property="og:locale" content="en_US" data-react-helmet="true" />
                <meta name="og:type" property="og:type" content="article" data-react-helmet="true" />
                <meta name="og:image" property="og:image" content="article" data-react-helmet="true" />
                {/* <!-- Full link to the current webpage address -->  */}
                <meta name="og:url" property="og:url" content="https://www.calabasasgaragedoor.org/" data-react-helmet="true" />
                {/* <!-- Maximum of 155 characters  --> */}
                <meta name="description" content="Your Trusted Source For Expert Garage Door Services In Calabasas, CA. We Offer Professional Installation, Repair, And Maintenance To Keep Your Garage Door Secure And Functioning Smoothly. Book Your Service Online Today." data-react-helmet="true" />
                {/* <!-- Maximum 65 characters --> */}
                <meta name="og:description" property="og:description" content="Your Trusted Source For Expert Garage Door Services In Calabasas, CA. We Offer Professional Installation, Repair, And Maintenance To Keep Your Garage Door Secure And Functioning Smoothly. Book Your Service Online Today." data-react-helmet="true" />
                <meta name="og:site_name" property="og:site_name" content={`Calabasas Garage Door`} data-react-helmet="true" />
                <meta name="keywords" content="Garage Door Broken Spring, Garage Door Spring Repair, Garage Door Broken Rollers, Garage Door Broken Cables, Garage Door Broken Hinges, Garage Door Broken Drums, Garage Door Off Track, Damage Garage Door Section, New Garage Door Installation, Garage Door Opener Won't Open Or Close, Garage Door Opener Remote Is Not Working, Garage Door Opener Keypad Is Not Working, Garage Door Maintenance, Garage Door Installation, Garage Door Opener Stripped Gear, Garage Door Opener Installation, Garage Door Opener Repair, Garage Door Repair, Garage Door Services, Garage Door Near me, Garage Door Spring Replacement, Garage Door Replacement, Garage Door Opener Chain Repair, Garage Door Opener Belt Repair, Garage Door Residential, Commercial Garage Door, Residential Garage Doors" data-react-helmet="true" />
            </Helmet>
            {/* MainBanner */}

            <div className="MainBanner">
                <div className="MainBannerLeft">
                    <h1>Calabasas, CA Garage Door Repair</h1>
                    <h2>Garage Door Near Me In Calabasas, California</h2>
                    <h3><img src={Checklist} alt="Locally Owned & Operated Calabasas Garage Door Repair" />Locally Owned & Operated</h3>
                    <h3><img src={Checklist} alt="Licensed | Bonded | Insured Calabasas Garage Door Repair" />Licensed | Bonded | Insured</h3>
                    <h3><img src={Checklist} alt="Satisfaction Guaranteed Calabasas Garage Door Repair" />Satisfaction Guaranteed</h3>
                    <span>
                        <a href="tel:747-338-6494">CALL 747-338-6494<img src={telephone} alt="" /></a>
                        <a href="/book">BOOK AN APPOINTMENT<img src={book} alt="" /></a>
                    </span>
                    <div className="GoogleButt">
                        <img src={Google} alt="Calabasas Garage Door Reviews" />
                        <h6>4.9 Stars - Based on 1,583 Reviews <a href={`/reviews/${LocationData.find(a => a.City == ("Calabasas").replace("%20", '')).City.replace(" ", '').toLowerCase()}`}>Read Reviews For Calabasas Location</a></h6>
                    </div>
                </div>
                <div className="MainBannerRight">
                    <img src={GarageImg6} alt="Expert Garage Door Services | Calabasas Garage Door | " />
                    <div className="MainBannerRightBubble1"></div>
                    <div className="MainBannerRightBubble2"></div>
                </div>
            </div>
            <div className="MainBanner2Top2">
                <div className="MainBanner2TopBox">
                    <h2>Calabasas Garage Door Services</h2>
                    <div className="MainBanner2TopBoxMenu">
                        <a href="/service/Garage-Door-Repair" className="MainBanner2TopBoxBox">
                            <h3>Garage Door Repair</h3>
                        </a>
                        <a href="/service/Garage-Door-Services" className="MainBanner2TopBoxBox">
                            <h3>Garage Door Services</h3>
                        </a>
                        <a href="/service/Garage-Door-Broken-Spring" className="MainBanner2TopBoxBox">
                            <h3>Garage Door Broken Spring</h3>
                        </a>
                        <a href="/service/Garage-Door-Opener-Repair" className="MainBanner2TopBoxBox">
                            <h3>Garage Door Opener</h3>
                        </a>
                    </div>
                </div>
            </div>
            <div className="MainBannerReviews">
                <div className="MainBannerReviewsHeader">
                    <h1>Proudly recognized as "#1 Garage Door Company" by industry experts.</h1>
                </div>
                <div className="MainBannerReviewsMenu">
                    <div className="MainBannerReviewsMenuBox">
                        <img src="https://upload.wikimedia.org/wikipedia/commons/thumb/8/8f/Yahoo%21_Finance_logo_2021.png/320px-Yahoo%21_Finance_logo_2021.png" alt="Expert Garage Door Services | Calabasas Garage Door | " />
                    </div>
                    <div className="MainBannerReviewsMenuBox">
                        <img src="https://cdn.worldvectorlogo.com/logos/marketwatch-logo.svg" alt="Expert Garage Door Services | Calabasas Garage Door | " />
                    </div>
                    <div className="MainBannerReviewsMenuBox">
                        <img src="https://1000logos.net/wp-content/uploads/2024/02/Business-Insider-Logo.png" alt="Expert Garage Door Services | Calabasas Garage Door | " />
                    </div>
                    <div className="MainBannerReviewsMenuBox">
                        <img src="https://1000logos.net/wp-content/uploads/2016/10/Associated-Press-logo.png" alt="Expert Garage Door Services | Calabasas Garage Door | " />
                    </div>
                    <div className="MainBannerReviewsMenuBox">
                        <img src="https://1000logos.net/wp-content/uploads/2022/05/Benzinga-Logo.png" alt="Expert Garage Door Services | Calabasas Garage Door | " />
                    </div>
                    <div className="MainBannerReviewsMenuBox">
                        <img src="https://www.iadea.com/wp-content/uploads/2018/10/digital-journal-logo.png" alt="Expert Garage Door Services | Calabasas Garage Door | " />
                    </div>
                </div>
            </div>
            <div className="MainBanner2">
                <div className="MainBanner2TopTop">
                    <div className="MainBanner2TopTopLeft">
                        <img src={GarageImg8} alt="Expert Garage Door Services | Calabasas Garage Door | Expert Garage Door Services" />
                    </div>
                    <div className="MainBanner2TopTopRight">
                        <div className="MainBanner2TopBox">
                            <h2>Expert Garage Door Services</h2>
                            <h3>Ensure your home’s security and efficiency with our professional garage door services.</h3>
                        </div>
                        <div className="MainBanner2TopBox">
                            <h2>Dependable Garage Door Inspections & Repairs</h2>
                            <h3>Rely on our expert technicians to thoroughly inspect and repair your garage door.</h3>
                        </div>
                        <div className="MainBanner2TopBox">
                            <h2>Premium Garage Door Maintenance</h2>
                            <h3>Prevent issues and extend the life of your garage door system with our top-quality maintenance services.</h3>
                        </div>
                    </div>
                </div>

                {/* Title */}
                <a href="tel:747-338-6494">CALL NOW 747-338-6494</a>
                <h1>Garage Door Repair Near Me</h1>
                <div className="MainBanner2Bottom">
                    <div className="MainBanner2BottomBox">
                        <h2>Garage Door Repair Services</h2>
                        <h3>
                            A malfunctioning garage door can disrupt your daily routine and compromise the security of your home. Our expert garage door repair services in Calabasas, CA, are designed to address any issue quickly and effectively. Whether it’s a broken spring, a faulty opener, or a door that’s off track, our skilled technicians have the experience and tools to get your garage door back in top condition.
                            <br />
                            <br />
                            We understand the urgency of garage door problems, which is why we offer prompt, same-day service for most repairs. Our team uses high-quality replacement parts to ensure lasting results, so you won’t have to worry about recurring issues. We also provide a thorough inspection during every repair to identify any underlying problems that could cause future headaches.
                            <br />
                            <br />

                            Safety is our top priority. A malfunctioning garage door can be dangerous, especially if it involves broken springs or cables. Trust our professionals to handle these repairs safely and efficiently, restoring your peace of mind.
                            <br />
                            <br />

                            <br />
                            Don’t let a faulty garage door disrupt your life. Contact us today to schedule a repair, and let our experts take care of your garage door needs with the professionalism and attention to detail you deserve.
                        </h3>
                        <a href="/service/Garage-Door-Repair">Learn More</a>
                    </div>
                    <div className="MainBanner2BottomBox">
                        <h2>Comprehensive Garage Door Services</h2>
                        <h3>
                            Your garage door is a crucial part of your home’s functionality and security, and it deserves expert care. Our comprehensive garage door services in Calabasas, CA, cover everything you need to keep your door operating smoothly and reliably. From installation and repair to routine maintenance and emergency services, we’ve got you covered.
                            <br />
                            <br />
                            Installation: Whether you’re building a new home or replacing an old garage door, our professional installation services ensure a perfect fit and flawless operation. We offer a wide selection of high-quality doors to match your style and budget.
                            <br />
                            <br />
                            Repair: When your garage door isn’t working as it should, our skilled technicians are ready to diagnose and fix the problem. We handle everything from broken springs and cables to malfunctioning openers, using top-grade parts to ensure lasting repairs.
                            <br />
                            <br />
                            Maintenance: Regular maintenance is key to extending the life of your garage door and preventing costly breakdowns. Our maintenance services include thorough inspections, lubrication of moving parts, and adjustments to keep your door running smoothly.
                            <br />
                            <br />
                            Emergency Services: Garage door issues can happen at any time, which is why we offer prompt emergency services. Whether it’s late at night or during the weekend, you can count on us to respond quickly and resolve the problem.
                            <br />
                            <br />
                            Trust us to provide the expert garage door services you need to keep your home safe, secure, and efficient.
                        </h3>
                        <a href="/service/Garage-Door-Services">Learn More</a>
                    </div>
                </div>
                <div className="MainBanner2Bottom">
                    <div className="MainBanner2BottomBox">
                        <h2>Garage Door Opener Services</h2>
                        <h3>
                            The garage door opener is the unsung hero of your garage door system, providing the convenience of smooth, automatic operation at the push of a button. When your garage door opener malfunctions, it can be a major hassle, leaving you stranded outside or unable to securely close your garage. That’s where our expert garage door opener services come in.
                            <br />
                            <br />
                            Serving Calabasas, CA, we specialize in the repair, replacement, and installation of all types of garage door openers. Whether your opener is making strange noises, struggling to lift the door, or has stopped working altogether, our skilled technicians can diagnose the issue and provide a quick and effective solution.
                            <br />
                            <br />
                            We work with top-quality openers from leading brands, ensuring you get a reliable, durable product that meets your needs. If your current opener is outdated or beyond repair, we can help you choose and install a new system with advanced features like smartphone connectivity, battery backup, and enhanced security options.
                            <br />
                            <br />
                            Our goal is to restore the convenience and security of your garage with minimal disruption to your daily routine. With our prompt service and attention to detail, you can trust us to get your garage door opener working perfectly again in no time.
                            <br />
                            <br />
                            Contact us today to schedule a service appointment and experience the difference that professional garage door opener services can make.
                        </h3>
                        <a href="/service/Garage-Door-Opener-Repair">Learn More</a>
                    </div>
                    <div className="MainBanner2BottomBox">
                        <h2>Garage Door Broken Spring Repair</h2>
                        <h3>
                            A broken garage door spring is one of the most common and frustrating issues homeowners face. The springs play a crucial role in lifting and lowering your garage door, so when they break, your door becomes heavy, difficult to operate, or completely inoperable. This not only causes inconvenience but also poses a significant safety risk.
                            <br />
                            <br />
                            Our expert technicians in Calabasas, CA, specialize in repairing and replacing broken garage door springs quickly and safely. We understand the urgency of a broken spring, and that’s why we offer prompt, same-day service to get your garage door back in working order as soon as possible.
                            <br />
                            <br />
                            When you choose our service, you can trust that we’ll use high-quality, durable springs that are suited to your specific garage door. Our team carefully measures and selects the appropriate springs to ensure smooth, balanced operation, reducing the strain on your garage door system and extending its lifespan.
                            <br />
                            <br />
                            Attempting to fix a broken spring yourself can be dangerous due to the high tension in the springs. Leave it to our trained professionals who have the right tools and experience to handle the repair safely and effectively.
                            <br />
                            <br />
                            Don’t let a broken garage door spring disrupt your day—contact us today for fast, reliable service that restores your garage door’s functionality and keeps your home secure.
                        </h3>
                        <a href="/service/Garage-Door-Broken-Spring">Learn More</a>
                    </div>
                </div>
            </div>
            <div className="MainBanner4">
                <div className="MainBanner4Left">
                    <img src={GarageImg4} alt="Expert Garage Door Services | Calabasas Garage Door | Calabasas Garage Door" />
                    <div className="MainBanner4LeftBubble1"></div>
                    <div className="MainBanner4LeftBubble2"></div>
                </div>
                <div className="MainBanner4Right">
                    <h1>OUR OUR SERVICES</h1>
                    <h2>At Calabasas, California Garage Door, we offer comprehensive Garage Door Services including Repair, inspection, repair, and maintenance. Our certified technicians ensure your Garage Door is safe, efficient, and in top condition. We use high-quality materials and state-of-the-art equipment to deliver exceptional results, providing peace of mind and enhancing your home's safety and comfort.</h2>
                    <div className="MainBanner4RightBenefits">
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Broken-Spring">Garage Door Broken Spring Calabasas, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Spring-Repair">Garage Door Spring Repair Calabasas, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Broken-Rollers">Garage Door Broken Rollers Calabasas, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Broken-Cables">Garage Door Broken Cables Calabasas, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Broken-Hinges">Garage Door Broken Hinges Calabasas, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Broken-Drums">Garage Door Broken Drums Calabasas, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Off-Track">Garage Door Off Track Calabasas, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Damage-Garage-Door-Section">Damage Garage Door Section Calabasas, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/New-Garage-Door-Installation">New Garage Door Installation Calabasas, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Wont-Open-Or-Close">Garage Door Opener Won't Open Or Close Calabasas, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Remote-Is-Not-Working">Garage Door Opener Remote Is Not Working Calabasas, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Keypad-Is-Not-Working">Garage Door Opener Keypad Is Not Working Calabasas, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Maintenance">Garage Door Maintenance Calabasas, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Installation">Garage Door Installation Calabasas, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Stripped-Gear">Garage Door Opener Stripped Gear Calabasas, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Installation">Garage Door Opener Installation Calabasas, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Repair">Garage Door Opener Repair Calabasas, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Repair">Garage Door Repair Calabasas, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Services">Garage Door Services Calabasas, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Near-me">Garage Door Near me Calabasas, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Spring-Replacement">Garage Door Spring Replacement Calabasas, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Replacement">Garage Door Replacement Calabasas, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Chain-Repair">Garage Door Opener Chain Repair Calabasas, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Opener-Belt-Repair">Garage Door Opener Belt Repair Calabasas, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Garage-Door-Residential">Garage Door Residential Calabasas, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Commercial-Garage-Door">Commercial Garage Door Calabasas, CA</a>
                        <a className="Header1BoxMenuBox" href="/service/Residential-Garage-Doors">Residential Garage Doors Calabasas, CA</a>
                    </div>
                </div>
            </div>
            <div className="MainBanner5">
                <div className="MainBanner5Left">
                    <h1>How to get in touch with Garage Door Services in Calabasas ?</h1>
                    <div className="MainBanner5LeftBox">
                        <h2>1</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Call us.</h3>
                            <h4>Contact our expert team by calling or visiting our website to book Calabasas Garage Door Services.</h4>
                        </div>
                    </div>
                    <div className="MainBanner5LeftBox">
                        <h2>2</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Provide some information.</h3>
                            <h4>Provide your address and specific details to our Garage Door professionals, including any unique aspects of your home that might affect the service.</h4>
                        </div>
                    </div>
                    <div className="MainBanner5LeftBox">
                        <h2>3</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>To know your situation more.</h3>
                            <h4>Share photos of your Garage Doorto help us understand its current condition and accurately estimate the service needed.</h4>
                        </div>
                    </div>
                    <div className="MainBanner5LeftBox">
                        <h2>4</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Date and Time.</h3>
                            <h4>Discuss the urgency of your service needs and agree on a tentative arrival date for our team.</h4>
                        </div>
                    </div>
                    <div className="MainBanner5LeftBox">
                        <h2>5</h2>
                        <div className="MainBanner5LeftBoxRigth">
                            <h3>Our team will do the best.</h3>
                            <h4>Our local service provider will determine the cost based on our standard pricing.</h4>
                        </div>
                    </div>
                    <a href="tel:747-338-6494">CALL NOW 747-338-6494</a>
                </div>
                <div className="MainBanner5Right">
                    <img src={GarageImg2} alt="Expert Garage Door Services | Calabasas Garage Door | " />
                    <div className="MainBanner5RightBubble1"></div>
                    <div className="MainBanner5RightBubble2"></div>
                </div>

            </div>
            <div className="MainBanner6">
                <h1>Trusted Mobile and Safe Service</h1>
                <div className="MainBanner6List">
                    <div className="MainBanner6TopBox">
                        <h2>Our Mission</h2>
                        <h3>Our mission is to connect you with the leading experts in garage door services in Calabasas. By leveraging the latest technology and specialized expertise, we deliver efficient, top-quality care for your garage door, ensuring your home remains secure and functional.</h3>
                    </div>
                    <div className="MainBanner6TopBox">
                        <h2>Our Goals</h2>
                        <h3>Our professionals are part of a premier network dedicated to excellence in every aspect of garage door services. We emphasize collaboration, bringing together the best talent and resources to handle even the most complex garage door challenges with efficiency and precision.</h3>
                    </div>
                    <div className="MainBanner6TopBox">
                        <h2>Our Values</h2>
                        <h3>Our local service providers in Calabasas prioritize safety for you, your family, pets, and neighbors while maintaining a commitment to environmental responsibility. We also uphold values of respect and kindness in every interaction, ensuring a positive experience from start to finish.</h3>
                    </div>
                </div>
            </div>
            <div className="MainBanner6">
                <h1>Real Testimonials from Our Valued Clients</h1>
                <h2>Calabasas Garage Services delivered exceptional garage door repair and inspection services. The technician arrived on time, was highly professional, and conducted a thorough job. They explained the entire process clearly and ensured everything was in perfect order before leaving. I highly recommend Calabasas Garage Services for their outstanding customer care and expertise. My garage door is now operating smoothly and securely, thanks to their top-notch work!</h2>
                <h3>5 stars company 🌟 🌟 🌟 🌟 🌟</h3>
            </div>
            <div className="BlogsListFaq">
                <div className="BlogsListFaqHead">
                    <h1>Frequently asked questions</h1>
                    <a href="/faq">View All</a>
                </div>
                <div className="BlogsListFaqList">
                    {FaqList.slice(getNumberFaq, (getNumberFaq + 5)).map((A, index) =>
                        <article key={index} className="FaqListBox">
                            <a href={`/faq/${A.questionPath.replace(/ /g, "_") + "_" + (A.faqId - 1)}`} >
                                <h1>{A.question}</h1>
                                {/* <h2>{A.answer.slice(0, 50 - 1) + "…"}</h2> */}
                                <h3><span> {A.category}</span></h3>
                            </a>
                        </article>

                    )}
                </div>
            </div>
            <div className="BlogsListReview">
                <div className="BlogsListReviewHead">
                    <h1>Related Articles</h1>
                    <a href="/blog">View All</a>
                </div>
                <div className="BlogsListReviewList">
                    {Blogs.slice(getNumberBlog, (getNumberBlog + 3)).map((A, index) =>

                        <a href={`/blog/${A.BlogPath.replace(/ /g, "_") + "_" + (A.BlogId - 1)}`} key={index} className="BlogsListReviewBox" >
                            <article>
                                <div className="BlogsListReviewBoxLeft">
                                    <img src={A.Photos} alt={A.BlogPath} />
                                </div>
                                <div className="BlogsListReviewBoxRight">
                                    <h1>{A.Title}</h1>
                                    <span>
                                        <h5>{readingTime(A)} MIN READ</h5>
                                        <h4>{A.Date}</h4>
                                    </span>
                                </div>
                            </article>
                        </a>
                    )}
                </div>
            </div>
            <div className="MainBanner7">
                <div className="MainBanner7Left">
                    <h1>The Importance of Professional Garage Door Services in Calabasas</h1>
                    <h2>Keeping your Garage Doorclean and well-maintained is essential for the safety and efficiency of your Garage Door or stove. In Calabasas, regular Garage Dooring is a critical task that ensures your Garage Dooroperates smoothly and reduces the risk of fire hazards. Here’s why professional Garage Door services are vital and what you can expect from the process.</h2>
                    <h2>
                        {/* (888) 269-4246 */}
                        <br />
                        Monday – Friday
                        <br />
                        8:00 a.m. – 8:00 p.m. ET
                    </h2>
                </div>
                <div className="MainBanner7Right">
                    <img src={GarageImg1} alt="Expert Garage Door Services | Calabasas Garage Door | " />
                    <div className="MainBanner7RightBubble1"></div>
                    <div className="MainBanner7RightBubble2"></div>
                </div>
            </div>
        </div>
    )
}

